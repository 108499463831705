import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menu" />
    </div>

    {/* <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine">
        <p className="app__specialMenu-menu_heading">Seasonal Bites</p>
        <div className="app__specialMenu_menu_items">
          {data.bites.map((bite, index) => (
            <MenuItem key={bite.title + index} title={bite.title} tags={bite.tags} />
          ))}
        </div>

        <p className="app__specialMenu-menu_heading">Cold</p>
        <div className="app__specialMenu_menu_items">
          {data.colds.map((cold, index) => (
            <MenuItem key={cold.title + index} title={cold.title} tags={cold.tags} />
          ))}
        </div>

        <p className="app__specialMenu-menu_heading">O-Cheop Bansang</p>
        <div className="app__specialMenu_menu_items">
          {data.bansangs.map((bansang, index) => (
            <MenuItem key={bansang.title + index} title={bansang.title} tags={bansang.tags} />
          ))}
        </div>

        <p className="app__specialMenu-menu_heading">Seasonal Banchan</p>
        <div className="app__specialMenu_menu_items">
          {data.banchans.map((banchan, index) => (
            <MenuItem key={banchan.title + index} title={banchan.title} tags={banchan.tags} />
          ))}
        </div>

        <p className="app__specialMenu-menu_heading">Dessert</p>
        <div className="app__specialMenu_menu_items">
          {data.desserts.map((dessert, index) => (
            <MenuItem key={dessert.title + index} title={dessert.title} tags={dessert.tags} />
          ))}
        </div>
      </div>

      {/* <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

    </div> */}
    <p className='p__opensans' style={{padding: '0 0 2rem'}}>
    <i>Nothing is more important to us than a pleasant dining experience of our
    guests.</i>
    <br></br>We would like to accommodate to your needs and requirements to our
    best ability. Due to limitations, we will not be able to accommodate to last
    minute requests.
    <br></br>Kindly note that we are unable to accommodate to certain shellfish, dairy,
    vegetarian and gluten-free dietary restrictions due to the
    nature of the cuisine.
    </p>
    {/* <a href={images.menu} className='custom__button' download="menu">Download Menu</a> */}
    <a href='https://drive.google.com/drive/folders/1p-oXO--8dSzQ0f0TYqqCEb9NheH7AIOd' target='_blank' className='custom__button'>View Menu</a>

  </div>
);

export default SpecialMenu;