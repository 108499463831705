import React from 'react';
import { FiInstagram, FiFacebook, FiTikTok } from 'react-icons/fi';

// import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const TikTokIcon = ({ color = "#faece2" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 42"
      width="27px"
      height="27px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    {/* <FooterOverlay />
    <Newsletter /> */}

    <div className="app__footer-links">

      <div className="app__footer-links_logo">
        <img src={images.onmi} alt="footer_logo" />
        <br/>
        {/* <img src={images.chopstick} alt="breakpoint" className="chopstick__img" style={{ marginTop: 15 }} /> */}
        <div className="app__footer-links_icons">
          <a href='https://www.facebook.com/profile.php?id=100090546798687 '>
            <FiFacebook/>
          </a>
          <a href='https://www.instagram.com/its_onmi/'>
            <FiInstagram/>
          </a>
          <a href='https://www.tiktok.com/@its_onmi?lang=en ' >
            <TikTokIcon/>
          </a>
          
        </div>
      </div>

    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2025 ONMI. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;