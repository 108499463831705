import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className='app__header app__wrapper section__padding' id='home'>
    <div className='app__wrapper_info'>
      <h1 className='app__header-h1'>온고지신</h1>
      <p className='p__opensans' style={{ margin: '2rem 0'}}>Guided by the philosophy of 온고지신 (温故知新)—&quot;By studying the past, you
can understand the present”. At ONMI, we are committed to bringing you an
innovative dining experience, one that balances timeless flavors with modern
techniques, all at a reasonable price. Complement your meal with our
traditional Korean drink selections, and enjoy the ambiance enhanced by
carefully curated art pieces from the Korean Peninsula.</p>
    </div>
    <div className='app__wrapper_img'>
      <img src={images.welcome} alt='header img' />
    </div>
  </div>
);

export default Header;
