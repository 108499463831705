import React from 'react';
import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div id='about' className='app__bg app__wrapper section__padding'>
    <div className='app__wrapper_img app__wrapper_img-reverse'>
      <img src={images.aboutus} alt='about us' />
    </div>
    <div className='app__wrapper_info'>
      <h1 className='headtext__josefinsans'>About Us</h1>
      <div className='app__chef-content'>
        <p className='p__opensans-alt' style={{textTransform: 'none'}}>At ONMI, we bring the essence of Korea’s culinary heritage into the
modern age. By blending timeless traditions with bold, contemporary
techniques. Our menu evolves to showcase the vibrant interplay between
classic ingredients and innovative ideas, offering a dining experience that
is both deeply rooted and refreshingly modern.</p>
      </div>
    </div>
  </div>
);

export default AboutUs;
