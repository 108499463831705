import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';
import Reservation from './Reservation';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [buttonPopup, setButtonPopup] = React.useState(false)
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.onmi} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="app__nav-p"><a href="#home">Home</a></li>
        <li className="app__nav-p"><a href="#about">About</a></li>
        <li className="app__nav-p"><a href="#menu">Menu</a></li>
        <li className="app__nav-p"><a href="#chef">Chef</a></li>
        <li className="app__nav-p"><a href="#contact">Contact</a></li>
      </ul>
      {/* <div className="app__navbar-login">
        <a href="http://www.sevenrooms.com/reservations/onmi" className="app__nav-p">Reserve a Table</a>
      </div> */}

      <div className='app__navbar-login'>
        <button onClick={() => setButtonPopup(true)} className='app__nav-r'>Reservation</button>
        <Reservation trigger={buttonPopup} setTrigger={setButtonPopup}>
          
        <p className='p__opensans-alt'>
                    <b><u>Reservation</u></b><br></br>
                    Tables will only be held for 15 minutes from the reservation time.
                    Group sizes of more than 8 will have to email your reservation request
                    directly to reservation@onmi-sg.com.
                  </p>

                  <p className='p__opensans-alt'>
                    <br></br><b><u>Cancellation Policy</u></b><br></br>

                    We ask that any changes to your reservation be made no less than 48
                    hours prior. For no shows or late amendments, you will be subject to a fee
                    of $30/Guest (Lunch Reservations) and $50/Guest (Dinner Reservations)
                    that will be applied.
                  </p>

                  <p className='p__opensans-alt'>
                    <br></br><b><u>Corkage</u></b><br></br>
                    Our corkage fee is $50 per 750ml, and $100 per bottle for spirits.
                  </p>
                  
                  <p className='p__opensans-alt'>
                    <br></br><b><u>Dietary & Special requests</u></b><br></br>
                    Kindly note that we are unable to accommodate to certain shellfish, dairy,
                    vegetarian and gluten-free dietary restrictions due to the nature of the
                    cuisine. We will not be able to accommodate to last minute requests.

                    Special requests are not guaranteed and table allocations are subject to
                    availability and restaurant discretion.
                  </p>

                  <p className='p__opensans-alt'>
                    <br></br><b><u>Young Diners</u></b><br></br>
                    As much as we would love for your little ones to dine with us, we regret to
                    inform we do not have Kid's Menu and are unable to accommodate unless
                    they are able to enjoy our current menu.
                  </p>
          <div className="app__navbar-reserve">
            <a href="http://www.sevenrooms.com/reservations/onmi" className="app__nav-ra">I agree</a>
          </div>
        </Reservation>
      </div>
      

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>About</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>Menu</a></li>
              <li><a href="#chef" onClick={() => setToggleMenu(false)}>Chef</a></li>
              <li><a href="#contact" onClick={() => setToggleMenu(false)}>Contact</a></li>
              {/* <li><a href="http://www.sevenrooms.com/reservations/onmi" onClick={() => setToggleMenu(false)}>Reserve a Table</a></li> */}
              <li>
                <button onClick={() => {setButtonPopup(true)}} className='app__nav-rs'>Reservation</button>
                <Reservation trigger={buttonPopup} setTrigger={setButtonPopup}>
                  
                  <p className='p__opensans-alt'>
                    <b><u>Reservation</u></b><br></br>
                    Tables will only be held for 15 minutes from the reservation time.
                    Group sizes of more than 8 will have to email your reservation request
                    directly to reservation@onmi-sg.com.
                  </p>

                  <p className='p__opensans-alt'>
                    <br></br><b><u>Cancellation Policy</u></b><br></br>

                    We ask that any changes to your reservation be made no less than 48
                    hours prior. For no shows or late amendments, you will be subject to a fee
                    of $30/Guest (Lunch Reservations) and $50/Guest (Dinner Reservations)
                    that will be applied.
                  </p>

                  <p className='p__opensans-alt'>
                    <br></br><b><u>Corkage</u></b><br></br>
                    Our corkage fee is $50 per 750ml, and $100 per bottle for spirits.
                  </p>
                  
                  <p className='p__opensans-alt'>
                    <br></br><b><u>Dietary & Special requests</u></b><br></br>
                    Kindly note that we are unable to accommodate to certain shellfish, dairy,
                    vegetarian and gluten-free dietary restrictions due to the nature of the
                    cuisine. We will not be able to accommodate to last minute requests.

                    Special requests are not guaranteed and table allocations are subject to
                    availability and restaurant discretion.
                  </p>

                  <p className='p__opensans-alt'>
                    <br></br><b><u>Young Diners</u></b><br></br>
                    As much as we would love for your little ones to dine with us, we regret to
                    inform we do not have Kid's Menu and are unable to accommodate unless
                    they are able to enjoy our current menu.
                  </p>

                  <div className="app__navbar-reserve">
                    <a href="http://www.sevenrooms.com/reservations/onmi" className="app__nav-ra">I agree</a>
                  </div>
                </Reservation>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;