import React from 'react';
import { SubHeading2 } from '../../components';
import { images } from '../../constants'
import './Chef.css';

const Chef = () => (
  <div id='chef' className='app__bg app__wrapper section__padding'>
    <div className='app__wrapper_img app__wrapper_img-reverse'>
      <img src={images.chef} alt='chef' />
    </div>
    <div className='app__wrapper_info'>
      <SubHeading2 title='Elevating Korean Cuisine in Singapore' />
      <h1 className='headtext__josefinsans'>Chef Ahn</h1>
      <div className='app__chef-content'>
        <p className='p__opensans-alt' style={{textTransform: 'none'}}>Born in 1992, Chef Ahn brings 32 years of life experience and a deep passion for Korean culinary artistry to the forefront. His journey to Singapore was driven by a singular vision: to share authentic Korean flavors with a diverse and discerning audience.

<br></br><br></br>In Chef Ahn's words, "<i>The main reason I came here is because I believe this is the place with the greatest potential. The diversity of people allows me to see how my cooking is evaluated, and I wanted to show what Korean cuisine made by a Korean chef truly is.</i>"

<br></br><br></br>With a wealth of experience and a dedication to his craft, Chef Ahn took the helm as Head Chef at ONMI in July 2024. Under his leadership, ONMI continues to redefine Korean cuisine, merging traditional flavors with innovative techniques, creating an unforgettable dining experience for all.</p>
      </div>
      <div className='app__chef-sign'>
        <p>Jae Woo AHN</p>
        <p className='p__opensans-alt'>Head Chef</p>
        {/* <img src={images.sign} alt='sign' /> */}
      </div>
    </div>
  </div>
);

export default Chef;
