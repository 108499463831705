import React from 'react';
import { SubHeading2 } from '../../components';

const FindUs = () => (
  <div className='app__bg app__wrapper section__padding' id='contact'>
    <div className='app__wrapper_info'>
      <SubHeading2 title='Contact' />
      <h1 className='headtext__josefinsans' style={{ marginBottom: '3rem'}}>Find Us</h1>
      <div className='app__wrapper-content'>
        <p className='p__opensans-alt'>ONMI Restaurant</p>
        <p className='p__opensans-alt'>107 Amoy St</p>
        <p className='p__opensans-alt'>Singapore 069927</p>
        <p> .</p>
        <a href="https://goo.gl/maps/AgKdQSdKPtfKoui1A" target='_blank' rel='noreferrer' className='custom__button'>View Map</a>
        <p className='p__opensans-alt'>
          <br/>For reservation: <a href="mailto:reservation@onmi-sg.com">
            reservation@onmi-sg.com
          </a>
          <br/>General inquiries: <a href="mailto:contact@onmi-sg.com">
            contact@onmi-sg.com
          </a>
        </p>
        
      </div>
      
    </div>
    <div className='app__wrapper_info'>
        <p className='p__josefinsans-alt' style={{margin: '2rem 0'}}>Opening Hours</p>
        <p className='p__opensans-alt'>Lunch</p>
        <p className='p__opensans-alt'>Wednesday - Thursday</p>
        <p className='p__opensans-alt'>11:30am to 2:30pm</p>
        <p className='p__opensans-alt'>(Last seating 1:30pm)</p>
        <p className='p__opensans-alt'><br/>Dinner</p>
        <p className='p__opensans-alt'>Tuesday - Saturday</p>
        <p className='p__opensans-alt'>6:00pm to 10:30pm</p>
        <p className='p__opensans-alt'>(Last seating 8:30pm)</p>
        <p className='p__opensans-alt'><br/>Closed on Sunday & Monday</p>
      {/* <img src={images.findus} alt='findus' /> */}
    </div>
  </div>
);

export default FindUs;
